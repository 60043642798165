/* global Munchkin */
'use strict';

/* Munchkin Code */

var didInit = false;

function initMunchkin() {
    if (didInit === false) {
        didInit = true;
        if ($('.mob-rfi-submission').data('rfi-munchkin-code')) {
            Munchkin.init($('.mob-rfi-submission').data('rfi-munchkin-code').toString());
        }
    }
}

var s = document.createElement('script');
s.type = 'text/javascript';
s.async = true;
s.src = '//munchkin.marketo.net/munchkin.js';
s.onreadstatechange = function () {
    if (this.readyState === 'complete' || this.readState === 'loaded') {
        initMunchkin();
    }
};

s.onload = initMunchkin;
document.getElementsByTagName('head')[0].appendChild(s);

/* Form Handling */

function getMobileMenuFormData(form) {
    var $homeMain = $('.mob-rfi-submission');
    // 165025 Start
    // var currentTimestamp = new Date();
    var date = new Date();
    var offset = (date.getTimezoneOffset() / 60) - 8;
    var edt = date.getTime() + (offset * 3600000);
    var currentTimestamp = new Date(edt);
    // 165025 End
    var data = {
        // Hidden Fields
        // eslint-disable-next-line
        institution: $homeMain.data('rfi-institution-code').toString(),
        llcp_transaction_id: String(Date.now()),
        raw_vendor_id: $homeMain.data('rfi-vendor-id').toString(),
        walden_permissions_opt_in: "Yes",
        walden_permissions_opt_in_timestamp: currentTimestamp.toISOString().slice(0, 10) + ' ' + currentTimestamp.toISOString().slice(11, 23),
        walden_permissions_statement: $('.request-info-mobile').text().replace(/\s+/g, " ").trim(),
        lead_source: $homeMain.data('rfi-lead-source').toString(),
        // Form Fields
        first_name: form.find('input[name="firstname"]').val(),
        last_name: form.find('input[name="lastname"]').val(),
        country: form.find('select[name="CountryMobMenu"]').val(),
        email: form.find('input[name="email"]').val(),
        phone: form.find('input[name="phonenumber"]').val(),
        primary_product__c: $homeMain.data('rfi-program-of-interest').toString()
    };
    if (data.country !== 'USA') {
        data.walden_permissions_statement = $('.request-info-mobile-nonusa').text().replace(/\s+/g, " ").trim();
    }
    // eslint-disable-next-line
    //48843 - SFCC sends commCode cookie value whenever calling Marketo RFI
    $.ajax({
        async: false,
        url: $('.c-module__reqform__submit').data('url'),
        type: 'GET',
        success: function (response) {
            if (response.trackingCode.isSet === false) {
                var getRFITrackingCode = $('.mobile-menu-rfi');
                data.raw_tracking_comm_code = getRFITrackingCode.data('rfi-tracking-code').toString();
            } else {
                data.raw_tracking_comm_code = response.trackingCode;
            }
        },
        failure: function () {
            // eslint-disable-next-line
            console.log('fetching tracking code failed');
        }
    });
    // eslint-disable-next-line
    //48843 - ends here
    return data;
}

function showError(form, message) {
    var errorContainer = $(form).find('.form-error');
    var errorMessage = errorContainer.find('.form-error__message');

    errorContainer.show();
    errorMessage.text(message);
}

function hideError(form) {
    var errorContainer = $(form).find('.form-error');
    var errorMessage = errorContainer.find('.form-error__message');

    errorContainer.hide();
    errorMessage.text('');
}

// 58339
$('#rfiThankYouModal').on('hidden.bs.modal', function (event) {
    $('.modal-backdrop').remove();
});
// 58339

function submitMobMenuL3Rfi(form) {
    form.spinner().start();
    var errorMessage = '';
    var data = getMobileMenuFormData(form);
    data.country = $('select[name="CountryMobMenu"] option:selected').html();  //  eslint-disable-line
    $.ajax({
        contentType: 'application/json',
        url: $('.mob-rfi-submission').data('rfi-api-l3endpoint'),
        type: 'post',
        data: JSON.stringify(data),
        dataType: 'json',
        success: function (responseObj) {
            form.spinner().stop();
            if (responseObj.success) {
                $(document).scrollTop(0);
                // 58339
                // window.location.reload();
                // 60325 - reset form
                // Bug#63753 Start
                $('#statementaggrement-nonusa').prop('checked', false);
                $('.request-info').find('.c-module__reqform__terms-nonusa').addClass('d-none');
                $('.request-info').find('.c-module__reqform__terms').removeClass('d-none');
                $('button.c-module__reqform__submit').attr('disabled', false);
                // Bug#63753 End

                $('.js-request-info-form-mob-menu')[0].reset();
                $('#rfiModal .close').trigger('click');
                $('#rfiThankYouModal').modal('show');
                // 58339
            } else {
                errorMessage = 'Error encountered when submitting the form';
                showError(form, errorMessage);
            }
        },
        error: function error(err) {
            form.spinner().stop();
            errorMessage = 'Error encountered when submitting the form';
            showError(form, errorMessage);
        }
    });
}

$(document).on('click', '.btn-rfi', function (e) {
    e.preventDefault();
    $('#rfiModal').modal('show');
});

$('.js-request-info-form-mob-menu').off('submit').on('submit', function (e) {
    e.preventDefault();
    var $homeMain = $('.mob-rfi-submission');
    var form = $(this);
    var valid = true;
    var errorMessage = '';
    hideError(form);
    if ($('#interestedInVal').val() === 'l1') {
        // Collect information
        var url = $homeMain.data('rfi-api-endpoint');
        var token = $homeMain.data('rfi-api-token');
        var data = getMobileMenuFormData(form);
        // Validation
        if (typeof url === 'undefined' || typeof token === 'undefined') {
            valid = false;
            errorMessage = 'Error encountered when submitting the form';
        }
        // Submit Form
        if (valid) {
            // eslint-disable-next-line
            form.spinner().start();
            $.ajax({
                // eslint-disable-next-line
                url: url,
                type: 'post',
                headers: {
                    'API-TOKEN': token,
                    'Content-Type': 'application/json'
                },
                dataType: 'json',
                data: JSON.stringify(data),
                success: function success(obj) {
                    form.spinner().stop();
                    if (obj.status === "200") {
                        $(document).scrollTop(0);
                        // 58339
                        // window.location.reload();
                        // Bug#63753 Start
                        $('#statementaggrement-nonusa').prop('checked', false);
                        $('.request-info').find('.c-module__reqform__terms-nonusa').addClass('d-none');
                        $('.request-info').find('.c-module__reqform__terms').removeClass('d-none');
                        $('button.c-module__reqform__submit').attr('disabled', false);
                        // Bug#63753 End

                        $('.js-request-info-form-mob-menu')[0].reset();
                        $('#rfiModal .close').trigger('click');
                        $('#rfiThankYouModal').modal('show');
                        // 58339
                        // form.find('input').val('');
                        // showError(form, 'Thank You. Congratulations on taking the first step toward reaching your goals.');
                    } else {
                        errorMessage = 'Error encountered when submitting the form';
                        showError(form, errorMessage);
                    }
                },
                error: function error(err) {
                    form.spinner().stop();
                    errorMessage = 'Error encountered when submitting the form';
                    showError(form, errorMessage);
                }
            });
        }
    } else {
        submitMobMenuL3Rfi(form);
    }
});
$(document).on('blur', '.js-request-info-form-mob-menu input.form-control', function () {
    var thisVal = $(this).val();
    if (thisVal.trim().length > 0) {
        $(this).addClass('filled');
    } else {
        $(this).removeClass('filled');
    }
});
$(document).on('blur', '.js-request-info-form-mob-menu select.form-control', function () {
    var thisVal = $(this).val();
    if (thisVal.trim().length !== -1) {
        $(this).addClass('filled');
    } else {
        $(this).removeClass('filled');
    }
});
// 61698
$(document).on('change', '.js-request-info-form-mob-menu select#countryMobMenu', function () {
    var thisVal = $(this).val();
    if (thisVal.trim().length !== -1) {
        $(this).addClass('filled');
    } else {
        $(this).removeClass('filled');
    }
    if (thisVal !== 'USA') {
        $('.request-info').find('.c-module__reqform__terms').addClass('d-none');
        $('.request-info').find('.c-module__reqform__terms-nonusa').removeClass('d-none');
        $('.statementaggrement-nonusa').prop('checked', false);
        $('button.c-module__reqform__submit').attr('disabled', true);
    } else {
        $('.request-info').find('.c-module__reqform__terms-nonusa').addClass('d-none');
        $('.request-info').find('.c-module__reqform__terms').removeClass('d-none');
        $('button.c-module__reqform__submit').attr('disabled', false);
    }
});
$('input.statementaggrement-nonusa').on('click', function () {
    if ($(this).prop("checked") === true) {
        $('button.c-module__reqform__submit').attr('disabled', false);
        $('.statementaggrement-nonusa').prop('checked', true);
    } else {
        $('button.c-module__reqform__submit').attr('disabled', true);
        $('.statementaggrement-nonusa').prop('checked', false);
    }
});
// 60325 - End

